'use strict';

var proxyCompare = require('proxy-compare');

var isObject = function isObject(x) {
  return typeof x === 'object' && x !== null;
};
var PROXY_STATE = Symbol();
var refSet = new WeakSet();
var buildProxyFunction = function buildProxyFunction(objectIs, newProxy, canProxy, defaultHandlePromise, snapCache, createSnapshot, proxyCache, versionHolder, proxyFunction) {
  if (objectIs === void 0) {
    objectIs = Object.is;
  }
  if (newProxy === void 0) {
    newProxy = function newProxy(target, handler) {
      return new Proxy(target, handler);
    };
  }
  if (canProxy === void 0) {
    canProxy = function canProxy(x) {
      return isObject(x) && !refSet.has(x) && (Array.isArray(x) || !(Symbol.iterator in x)) && !(x instanceof WeakMap) && !(x instanceof WeakSet) && !(x instanceof Error) && !(x instanceof Number) && !(x instanceof Date) && !(x instanceof String) && !(x instanceof RegExp) && !(x instanceof ArrayBuffer);
    };
  }
  if (defaultHandlePromise === void 0) {
    defaultHandlePromise = function defaultHandlePromise(promise) {
      switch (promise.status) {
        case 'fulfilled':
          return promise.value;
        case 'rejected':
          throw promise.reason;
        default:
          throw promise;
      }
    };
  }
  if (snapCache === void 0) {
    snapCache = new WeakMap();
  }
  if (createSnapshot === void 0) {
    createSnapshot = function createSnapshot(target, receiver, version, handlePromise) {
      if (handlePromise === void 0) {
        handlePromise = defaultHandlePromise;
      }
      var cache = snapCache.get(receiver);
      if ((cache == null ? void 0 : cache[0]) === version) {
        return cache[1];
      }
      var snap = Array.isArray(target) ? [] : Object.create(Object.getPrototypeOf(target));
      proxyCompare.markToTrack(snap, true);
      snapCache.set(receiver, [version, snap]);
      Reflect.ownKeys(target).forEach(function (key) {
        var value = Reflect.get(target, key, receiver);
        if (refSet.has(value)) {
          proxyCompare.markToTrack(value, false);
          snap[key] = value;
        } else if (value instanceof Promise) {
          Object.defineProperty(snap, key, {
            get: function get() {
              return handlePromise(value);
            }
          });
        } else if (value != null && value[PROXY_STATE]) {
          snap[key] = snapshot(value, handlePromise);
        } else {
          snap[key] = value;
        }
      });
      return Object.freeze(snap);
    };
  }
  if (proxyCache === void 0) {
    proxyCache = new WeakMap();
  }
  if (versionHolder === void 0) {
    versionHolder = [1];
  }
  if (proxyFunction === void 0) {
    proxyFunction = function proxyFunction(initialObject) {
      if (!isObject(initialObject)) {
        throw new Error('object required');
      }
      var found = proxyCache.get(initialObject);
      if (found) {
        return found;
      }
      var version = versionHolder[0];
      var listeners = new Set();
      var notifyUpdate = function notifyUpdate(op, nextVersion) {
        if (nextVersion === void 0) {
          nextVersion = ++versionHolder[0];
        }
        if (version !== nextVersion) {
          version = nextVersion;
          listeners.forEach(function (listener) {
            return listener(op, nextVersion);
          });
        }
      };
      var propListeners = new Map();
      var getPropListener = function getPropListener(prop) {
        var propListener = propListeners.get(prop);
        if (!propListener) {
          propListener = function propListener(op, nextVersion) {
            var newOp = [].concat(op);
            newOp[1] = [prop].concat(newOp[1]);
            notifyUpdate(newOp, nextVersion);
          };
          propListeners.set(prop, propListener);
        }
        return propListener;
      };
      var popPropListener = function popPropListener(prop) {
        var propListener = propListeners.get(prop);
        propListeners.delete(prop);
        return propListener;
      };
      var baseObject = Array.isArray(initialObject) ? [] : Object.create(Object.getPrototypeOf(initialObject));
      var handler = {
        get: function get(target, prop, receiver) {
          if (prop === PROXY_STATE) {
            var state = [target, receiver, version, createSnapshot, listeners];
            return state;
          }
          return Reflect.get(target, prop, receiver);
        },
        deleteProperty: function deleteProperty(target, prop) {
          var _PROXY_STATE;
          var prevValue = Reflect.get(target, prop);
          var childListeners = prevValue == null ? void 0 : (_PROXY_STATE = prevValue[PROXY_STATE]) == null ? void 0 : _PROXY_STATE[4];
          if (childListeners) {
            childListeners.delete(popPropListener(prop));
          }
          var deleted = Reflect.deleteProperty(target, prop);
          if (deleted) {
            notifyUpdate(['delete', [prop], prevValue]);
          }
          return deleted;
        },
        set: function set(target, prop, value, receiver) {
          var _PROXY_STATE2, _Object$getOwnPropert;
          var hasPrevValue = Reflect.has(target, prop);
          var prevValue = Reflect.get(target, prop, receiver);
          if (hasPrevValue && objectIs(prevValue, value)) {
            return true;
          }
          var childListeners = prevValue == null ? void 0 : (_PROXY_STATE2 = prevValue[PROXY_STATE]) == null ? void 0 : _PROXY_STATE2[4];
          if (childListeners) {
            childListeners.delete(popPropListener(prop));
          }
          if (isObject(value)) {
            value = proxyCompare.getUntracked(value) || value;
          }
          var nextValue = value;
          if ((_Object$getOwnPropert = Object.getOwnPropertyDescriptor(target, prop)) != null && _Object$getOwnPropert.set) ; else if (value instanceof Promise) {
            value.then(function (v) {
              value.status = 'fulfilled';
              value.value = v;
              notifyUpdate(['resolve', [prop], v]);
            }).catch(function (e) {
              value.status = 'rejected';
              value.reason = e;
              notifyUpdate(['reject', [prop], e]);
            });
          } else {
            var _value, _nextValue;
            if (!((_value = value) != null && _value[PROXY_STATE]) && canProxy(value)) {
              nextValue = proxy(value);
            }
            if ((_nextValue = nextValue) != null && _nextValue[PROXY_STATE]) {
              nextValue[PROXY_STATE][4].add(getPropListener(prop));
            }
          }
          Reflect.set(target, prop, nextValue, receiver);
          notifyUpdate(['set', [prop], value, prevValue]);
          return true;
        }
      };
      var proxyObject = newProxy(baseObject, handler);
      proxyCache.set(initialObject, proxyObject);
      Reflect.ownKeys(initialObject).forEach(function (key) {
        var desc = Object.getOwnPropertyDescriptor(initialObject, key);
        if (desc.get || desc.set) {
          Object.defineProperty(baseObject, key, desc);
        } else {
          proxyObject[key] = initialObject[key];
        }
      });
      return proxyObject;
    };
  }
  return [
  proxyFunction,
  PROXY_STATE, refSet,
  objectIs, newProxy, canProxy, defaultHandlePromise, snapCache, createSnapshot, proxyCache, versionHolder];
};
var _buildProxyFunction = buildProxyFunction(),
  proxyFunction = _buildProxyFunction[0];
function proxy(initialObject) {
  if (initialObject === void 0) {
    initialObject = {};
  }
  return proxyFunction(initialObject);
}
function getVersion(proxyObject) {
  var state = proxyObject == null ? void 0 : proxyObject[PROXY_STATE];
  return state == null ? void 0 : state[2];
}
function subscribe(proxyObject, callback, notifyInSync) {
  if (process.env.NODE_ENV !== "production" && !(proxyObject != null && proxyObject[PROXY_STATE])) {
    console.warn('Please use proxy object');
  }
  var promise;
  var ops = [];
  var listeners = proxyObject[PROXY_STATE][4];
  var listener = function listener(op) {
    ops.push(op);
    if (notifyInSync) {
      callback(ops.splice(0));
      return;
    }
    if (!promise) {
      promise = Promise.resolve().then(function () {
        promise = undefined;
        if (listeners.has(listener)) {
          callback(ops.splice(0));
        }
      });
    }
  };
  listeners.add(listener);
  return function () {
    return listeners.delete(listener);
  };
}
function snapshot(proxyObject, handlePromise) {
  if (process.env.NODE_ENV !== "production" && !(proxyObject != null && proxyObject[PROXY_STATE])) {
    console.warn('Please use proxy object');
  }
  var _ref = proxyObject[PROXY_STATE],
    target = _ref[0],
    receiver = _ref[1],
    version = _ref[2],
    createSnapshot = _ref[3];
  return createSnapshot(target, receiver, version, handlePromise);
}
function ref(obj) {
  refSet.add(obj);
  return obj;
}
var unstable_buildProxyFunction = buildProxyFunction;

exports.getVersion = getVersion;
exports.proxy = proxy;
exports.ref = ref;
exports.snapshot = snapshot;
exports.subscribe = subscribe;
exports.unstable_buildProxyFunction = unstable_buildProxyFunction;
